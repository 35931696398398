@font-face {
    font-family: SofiaPro-semibold;
    src: url('./assets/sofiapro-semibold.otf');
  }

  @font-face {
    font-family: SofiaPro-regular;
    src: url('./assets/sofiapro-regular.woff');
  }


* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  
  body {
    font: 400 14px Roboto, sans-serif;
    background: #f0f0f5;
    -webkit-font-smoothing: antialiased;
  }
  
  input, button, textarea {
    font: 400 18px Roboto, sans-serif;
  }
  
  button {
    cursor: pointer;
  }

  .lower{
    color: #F34C4C;
    }

  .high{
    color: #3F93F4;
  }