body {
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    margin: 0;
    height: 100%;
    background-color: #1A212D;
  }

  .team-container {
    display: flex;
    justify-content: space-around;
  }
    
  img {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  .link{
    text-decoration: none;
  }

  .back-logo{
    position: absolute;
    max-width: 200px;
    margin-top: 40px;
    margin-left: 45px;
    
  }

  .header{
    background-color: #2A2E38;
    width: 1400px;
    height: 250px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);

    margin-top: 130px;
    border-radius: 5px;

    display: flex;
  }

  .img-team{
    width: 200px;
    margin-left: 100px;
  }

  .info-team{
    width: 100%;
    height: 100%;
  }

  .info-name{
    font-family: SofiaPro-semibold;
    color: white;
    font-size: 90px;
    text-align: center; 
    line-height: 90px;

  }

  .info-row{
    margin-top: 60px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    font-family: SofiaPro-regular;
    font-size: 40px;
    color: white;

    padding: 0 100px;
  }

  .info-container {
    display: flex;
    justify-content: center;

    margin-top: 40px;
  }

  .stats-section{
    background-color: #2A2E38;
    width: 680px;
    border-radius: 5px;
    margin-bottom: 50px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }

  .second{
    margin-left: 40px;
  }

  .stats-title{
    font-family: SofiaPro-semibold;
    font-size: 45px;
    color: white;

    margin-left: 25px;
  }

  .stats-info{
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }

  .table-striped{
    font-family: SofiaPro-regular;
    color: white;
    font-size: 30px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 650px;
    background-color: #181A21;
    margin-bottom: 15px;
  }

  .table-value{
    text-align: right;
  }

  .table-title{
    width: 70%;
  }

  th, td {
    text-align: left;
    padding: 16px;
    font-weight: normal;
  }
  
  tr:nth-child(even) {
    background-color: #2A2E38;
  }

  .data-container{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    font-family: SofiaPro-regular;
    font-size: 30px;
    color: white;
  }

  .data-element{
    width: 100%;
  }

  .matches{
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .matches-winrate{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;

    font-family: SofiaPro-semibold;
    font-size: 36px;
  }

  .matches-value{
    font-size: 30px;
    align-self: flex-end;
    margin-left: 10px;
  }

  .matches-element{
    display: flex;
  }

  .bans{
    margin-top: 40px;
    text-align: center;
  }

  .bans-row{
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
    padding: 0 100px;
    width: 100%;
  }

  .bans-value img{
    border-radius: 500px;
    width: 50px;
  }

  .bans-value div{
    margin-top: 1px;
  }

  .other-data{
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
  }

  .icon{
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
  
  .icon-text{
    display: flex;
  }